import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext } from "react";
import MoreSettings from "src/components/overlayButtons/MoreSettings";
import { ROLES_LEVEL } from "src/constants";
import { UserContext } from "..";
import HeaderUserbox from "./Userbox";
import { useRecoilState } from "recoil";
import { isSidebarOpenState } from "src/store";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: flex-end;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

export default function Header() {
  const { breakpoints } = useTheme();
  const user = useContext(UserContext);
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState);

  return (
    <HeaderWrapper
      as={Paper}
      id="HeaderWrapper"
      display="flex"
      sx={{
        mb: 4,
        display: "flex",
        borderRadius: 0,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottomWidth: "1px",
      }}
    >
      <Stack spacing={2} direction="row" alignItems="center">
        <MoreSettings
          isSysAdmin={ROLES_LEVEL[user.role] >= ROLES_LEVEL.sysAdmin}
        />
        <HeaderUserbox />
        <Tooltip arrow title="Toggle Menu">
          {isMobile &&
            <IconButton
              color="primary"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              {isSidebarOpen &&  <CloseTwoToneIcon fontSize="medium" />}
              {!isSidebarOpen && <MenuTwoToneIcon fontSize="medium" />}
            </IconButton>
          }
        </Tooltip>
      </Stack>
    </HeaderWrapper>
  );
}
