import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import Header from './Header';
import Sidebar from './Sidebar';
import { createContext } from 'react';
import { useUser } from 'src/hooks/useUser';

export const UserContext = createContext()

const SidebarLayout = ({ children }) => {
  const user = useUser();
  const theme = useTheme();

  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',

        '.MuiPageTitle-wrapper': {
          background:
            theme.palette.mode === 'dark'
              ? theme.colors.alpha.trueWhite[5]
              : theme.colors.alpha.white[50],
          marginBottom: `${theme.spacing(4)}`,
        }
      }}
    >
      <UserContext.Provider value={user}>
        <Header />
        <Sidebar />
      </UserContext.Provider>
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          padding: theme.spacing(2),
          display: 'block',
          flex: 1,
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            ml: `${theme.sidebar.width}`
          }
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node
};

export default SidebarLayout;
