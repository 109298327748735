export const ROLES = {
  'invited': 'invited',
  'inspector': 'inspector',
  'controller': 'controller',
  'admin': 'admin',
  'owner': 'owner',
  'sysAdmin': 'sysAdmin',
};

const EMPTY_LABEL = {
  value: '',
  name: '',
  id: 0
}

export const DEFAULT_PASSING = {
  id: 0,
  createdAt: new Date(),
  labelId: 0,
  confidence: 0.5,
  image: "",
  imagePath: "",
  fps: 0,
  speed: 0,
  label: EMPTY_LABEL,
  correctLabel: EMPTY_LABEL,
  edgeLane: undefined,
  label: {
    name: "",
    id: 0,
    value: "",
  },
}

export const PASSINGS_HEADERS = {
  ID: 'id',
  TIMESTAMP: 'timestamp',
  LABEL: 'label',
  CORRECTED: 'corrected',
  CONFIDENCE: 'confidence',
  SPEED: 'speed',
  FPS: 'fps',
  LANE_AND_DIRECTION: 'laneAndDirection',
  IMAGE: 'image',
}

export const DEFAULT_HEADERS = [
  PASSINGS_HEADERS.ID,
  PASSINGS_HEADERS.TIMESTAMP,
  PASSINGS_HEADERS.LABEL,
  PASSINGS_HEADERS.CONFIDENCE,
  PASSINGS_HEADERS.LANE_AND_DIRECTION,
  PASSINGS_HEADERS.IMAGE
]

export const DEFAULT_SYSADMIN_HEADERS = [
  PASSINGS_HEADERS.ID,
  PASSINGS_HEADERS.TIMESTAMP,
  PASSINGS_HEADERS.LABEL,
  PASSINGS_HEADERS.CORRECTED,
  PASSINGS_HEADERS.CONFIDENCE,
  PASSINGS_HEADERS.SPEED,
  PASSINGS_HEADERS.FPS,
  PASSINGS_HEADERS.LANE_AND_DIRECTION,
  PASSINGS_HEADERS.IMAGE,
]

export const ROLES_LEVEL = {
  'invited': 0,
  'inspector': 2,
  'controller': 4,
  'admin': 6,
  'owner': 8,
  'sysAdmin': 10,
};

export const THEMES = {
  PURE_LIGHT_THEME: 'PureLightTheme',
  GREY_GOOSE_THEME: 'GreyGooseTheme',
  PURPLE_FLOW_THEME: 'PurpleFlowTheme',
  NORDIC_MIDNIGHT: 'NordicMidnight',
  DEFAULT_THEME: 'DefaultTheme',
  NOCTURNE_THEME: 'NocturneTheme'
}

export const TABLE_MAX_HEIGHT_BY_THEME = {
  [THEMES.DEFAULT_THEME]: 235,
  [THEMES.PURE_LIGHT_THEME]: 255,
  [THEMES.PURPLE_FLOW_THEME]: 305,
  [THEMES.GREY_GOOSE_THEME]: 255,
  [THEMES.NORDIC_MIDNIGHT]: 250,
  [THEMES.NOCTURNE_THEME]: 260,
};

export const FORMATS = {
  OLD: 'old',
  V2: 'V2'
}

export const VEHICLE_FREQUENCY = {
  'person': 0,
  'bicycle': 1,
  'car': 2,
  'motorcycle': 3,
  'caravan': 4,
  'bus': 5,
  'truck_cylinder': 6,
  'truck_open': 7,
  'truck_closed': 8,
  'van': 9,
  'pickup': 10,
  'camping_van': 11,
  'trailer': 12,
  'taxi_car': 13,
  'truck_trailer_open': 14,
  'truck_trailer_closed': 15,
  'taxi_van': 16,
  'truck_trailer_cylinder': 17,
  'truck_cement': 18,
  'tracktor': 19,
  'police': 20,
  'ambulance': 21,
  'fire': 22,
  'truck_car_transport': 23
};

export const COLORS = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
];

export const GRAPH_TYPES = {
  STACKED_BAR: "stackedBar",
  LINE_CHART: "lineChart",
  STACKED_AREA: "stackedArea",
};