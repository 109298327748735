import axios from "axios";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { getViewAsCompanyValue } from "src/components/utilities";
import {
  DEFAULT_HEADERS,
  DEFAULT_SYSADMIN_HEADERS,
  PASSINGS_HEADERS,
  ROLES,
  ROLES_LEVEL
} from "src/constants";
import useSWR from "swr";

const fetcher =
  (params = {}) =>
  (url) =>
    axios
      .get(url, { params: params })
      .then(
        (success) => {
          return success.data;
        },
        (err) => {
          if (err.response.status === 401) {
            console.log("User was not authenticated properly");
            localStorage.removeItem("dts-should-redirect");
            localStorage.removeItem("viewAsCompany");
          }
        }
      )
      .then((data) => {
        return { user: data || null };
      });

export function useUser({ redirectTo, redirectIfFound, restrictToRole } = {}) {
  const viewAsCompany = getViewAsCompanyValue();
  const router = useRouter();
  const { data, error, isLoading } = useSWR(
    "/api/auth/user",
    fetcher(viewAsCompany)
  );

  const user = data?.user;
  const finished = Boolean(data);
  const hasUser = Boolean(user && Object.keys(user).length !== 0);
  const isSysAdminOrAbove =
    ROLES_LEVEL[user?.role || ROLES.inspector] >= ROLES_LEVEL.sysAdmin;
  const defaultHeader = isSysAdminOrAbove
    ? DEFAULT_SYSADMIN_HEADERS
    : DEFAULT_HEADERS;

  useEffect(() => {
    if (restrictToRole && data?.user) {
      if (ROLES_LEVEL[data?.user?.role] < ROLES_LEVEL[restrictToRole]) {
        router.push(redirectTo || "/dashboard");
        return;
      }
    }

    if (!redirectTo || isLoading || !finished) return;

    if (redirectTo && !redirectIfFound && !hasUser) {
      localStorage.removeItem("dts-should-redirect");
      router.push(redirectTo);
    } else if (redirectIfFound && hasUser) router.push(redirectTo);
  }, [data, isLoading]);


  const headersFromCompany = useMemo(() => {
    const headers = data?.user?.company?.settings?.passingsTableHeaders;

    if (ROLES_LEVEL[user?.role] < ROLES_LEVEL.admin && Array.isArray(headers)) {
      return headers?.filter?.((header) => header !== PASSINGS_HEADERS.CORRECTED);
    };

    return headers;
  }, [data]);

  if (error) return null;

  return {
    ...user,
    companySettings: {
      passingsTableHeaders:
        Array.isArray(headersFromCompany) ? headersFromCompany : defaultHeader,
    },
    hasUser: hasUser,
    roleLevel: ROLES_LEVEL[user?.role],
  };
}
