import {
  BusinessCenterRounded,
  LabelRounded,
  LocalShippingRounded,
  PeopleRounded,
  PieChartRounded,
  RouterRounded,
} from "@mui/icons-material";
import { Button, List, ListItem } from "@mui/material";
import { useTranslation } from "next-i18next";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useContext } from "react";
import { useRecoilState } from "recoil";
import { ROLES_LEVEL } from "src/constants";
import { isSidebarOpenState } from "src/store";
import { UserContext } from "..";
import { MenuWrapper, SubMenuWrapper } from "./styledMenu";

export default function SidebarMenu() {
  const { t } = useTranslation('common');
  const user = useContext(UserContext);
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState);
  const currentRoleLevel = ROLES_LEVEL[user?.role];
  const router = useRouter();
  const currentRoute = router.pathname;

  const commonProps = {
    closeSidebar: () => setIsSidebarOpen(false),
    currentRoute,
    currentRoleLevel,
  };

  return (
    <MenuWrapper>
      <List component="div">
        <MenuItem
          {...commonProps}
          linkTo="/dashboard"
          Icon={PieChartRounded}
          name={t('sidebar.overview')}
        />
        <MenuItem
          {...commonProps}
          linkTo="/dashboard/passings"
          Icon={LocalShippingRounded}
          name={t('sidebar.passings')}
        />
        <MenuItem
          {...commonProps}
          linkTo="/dashboard/edges"
          Icon={RouterRounded}
          name={t('sidebar.edges')}
          roleLevel={ROLES_LEVEL.admin}
        />
        <MenuItem
          {...commonProps}
          linkTo="/dashboard/labels"
          Icon={LabelRounded}
          name={t('sidebar.labels')}
        />
        <MenuItem
          {...commonProps}
          linkTo="/dashboard/labelgroups"
          Icon={LabelRounded}
          name={t('sidebar.labelGroups')}
        />
        <MenuItem
          {...commonProps}
          linkTo="/dashboard/companies"
          Icon={BusinessCenterRounded}
          name={t('sidebar.companies')}
          roleLevel={ROLES_LEVEL.admin}
        />
        <MenuItem
          {...commonProps}
          linkTo="/dashboard/users"
          Icon={PeopleRounded}
          name={t('sidebar.users')}
          roleLevel={ROLES_LEVEL.admin}
        />
      </List>
    </MenuWrapper>
  );
}

const MenuItem = ({
  currentRoute,
  linkTo,
  closeSidebar,
  Icon,
  name,
  currentRoleLevel,
  roleLevel = ROLES_LEVEL.inspector,
}) => (
  <>
    {roleLevel <= currentRoleLevel && (
      <SubMenuWrapper>
        <List sx={{ marginBottom: 2 }} component="div">
          <ListItem component="div">
            <NextLink
              style={{ width: "100%", textDecoration: "none" }}
              href={linkTo}
              passHref
            >
              <Button
                className={linkTo === currentRoute ? "active" : ""}
                component="a"
                onClick={closeSidebar}
                startIcon={<Icon />}
              >
                {name}
              </Button>
            </NextLink>
          </ListItem>
        </List>
      </SubMenuWrapper>
    )}
  </>
);
